<template>
  <Delete
    action-handler="housekeeping-cycles/delete"
    :payload-value="record.id"
    :title="$t('title')"
    :description="$t('desc', { type: record.type })"
    :success-title="$t('deleted-title')"
    :success-description="$t('deleted-desc', { type: record.type })"
    @close="$emit('delete')"
  />
</template>

<i18n>
{
  "en": {
    "title": "Delete Cycle?",
    "desc": "Are you use you want to delete <strong>{type}</strong> cycle?",
    "deleted-title": "Cycle deleted",
    "deleted-desc": "Cycle <strong>{type}</strong> has been removed."
  },
  "ja": {
    "title": "サイクルを削除しますか？",
    "desc": "本当にこの<strong>{type}</strong>サイクルを削除してもよろしいですか？",
    "deleted-title": "サイクル削除完了",
    "deleted-desc": "サイクル<strong>{type}</strong>が削除されました"
  }
}
</i18n>

<script>
import Delete from '@/components/PopupDelete';

export default {
  name: 'DeleteCycle',
  components: {
    Delete,
  },
  props: {
    record: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['delete'],
};
</script>
