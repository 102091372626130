<template>
  <a-form
    ref="form"
    :model="form"
    :rules="rules"
    class="columns is-mini-gap is-multiline"
  >
    <div class="column is-12">
      <h3 class="has-text-black has-text-weight-bold is-size-4">
        {{ form.id ? $t('Edit Housekeeping Cycle') : $t('Add Housekeeping Cycle') }}
      </h3>
    </div>
    <a-form-item
      class="column is-12"
      :label="$t('Type')"
      name="type"
    >
      <a-select
        v-model:value="form.type"
        :size="size"
        aria-autocomplete="off"
        autocomplete="off"
        @change="onTypeChange"
      >
        <a-select-option
          v-for="(v, key) in typeOptions"
          :key="key"
          :value="v"
        >
          {{ $t(v) }}
        </a-select-option>
      </a-select>
    </a-form-item>

    <a-form-item
      v-show="form.type !== typeOptions.VACANCY"
      class="column is-12"
      :label="$t('Above/Below')"
      name="threshold"
    >
      <a-select
        v-model:value="form.threshold"
        :size="size"
        aria-autocomplete="off"
        autocomplete="off"
      >
        <a-select-option
          v-for="(v, key) in thresholdOptions"
          :key="key"
          :value="v"
        >
          {{ $t(v) }}
        </a-select-option>
      </a-select>
    </a-form-item>

    <a-form-item
      class="column is-12"
      :label="$t('No. of Nights')"
      name="nights"
    >
      <a-input-number
        v-model:value="form.nights"
        style="width: 100%"
        min="0"
        :size="size"
      />
    </a-form-item>

    <a-form-item
      v-show="form.type !== typeOptions.VACANCY"
      class="column is-12"
      :label="$t('Every')"
      name="every"
    >
      <a-input-number
        v-model:value="form.every"
        style="width: 100%"
        min="0"
        :size="size"
      />
    </a-form-item>

    <div class="column is-12">
      <div class="columns is-vcentered">
        <div class="column">
          <div class="has-text-weight-bold">
            {{ $t('Status') }}
          </div>
          <div>
            {{ $t('status-help') }}
          </div>
        </div>
        <div class="column is-narrow">
          <a-switch v-model:checked="form.active" />
        </div>
      </div>
    </div>

    <div class="column has-text-right is-12 m-t-4x">
      <a-button
        :disabled="loading"
        :size="size"
        class="button-secondary-outline m-r-3x"
        style="min-width: 115px"
        @click="resetFields"
      >
        {{ $t('Cancel') }}
      </a-button>
      <a-button
        :loading="loading"
        :size="size"
        style="min-width: 115px"
        type="primary"
        @click="handleSubmit"
      >
        {{ $t('Save') }}
      </a-button>
    </div>
  </a-form>
</template>

<i18n src="@/locales/components/housekeeping-cycles.json"></i18n>
<i18n>
{
  "en": {
    "Add Housekeeping Cycle": "Add Housekeeping Cycle",
    "Edit Housekeeping Cycle": "Edit Housekeeping Cycle",
    "Type is required": "Type is required",
    "No. of Nights is required": "No. of Nights is required",
    "Above/Below is required": "Above/Below is required",
    "Every is required": "Every is required",
    "status-help": "Only 1 above, 1 below, and 1 vacancy can be active."
  },
  "ja": {
    "Add Housekeeping Cycle": "中間清掃設定を追加",
    "Edit Housekeeping Cycle": "中間清掃設定を編集",
    "Type is required": "清掃パターンは必須項目です",
    "No. of Nights is required": "泊数設定は必須項目です",
    "Above/Below is required": "以上/以下（指定した数を含む）は必須項目です",
    "Every is required": "清掃間隔は必須項目です",
    "status-help": "複数サイクル設定がある場合１つのみ有効にできます。 "
  }
}
</i18n>

<script>
import { TYPE, THRESHOLD } from '@/config/housekeeping-cycles';

export default {
  name: 'CycleForm',
  props: {
    property: {
      type: Object,
      required: true,
    },
  },
  emits: ['close', 'cancel'],
  data() {
    return {
      typeOptions: TYPE,
      thresholdOptions: THRESHOLD,
      form: {
        type: undefined,
        threshold: undefined,
        nights: undefined,
        every: undefined,
        active: false,
      },
      size: 'large',
      loading: false,
    };
  },
  computed: {
    rules() {
      const rules = {
        type: [
          {
            required: true,
            message: this.$t('Type is required'),
            trigger: ['blur', 'change'],
          },
        ],
        nights: [
          {
            required: true,
            type: 'number',
            transform: (value) => parseInt(value, 10) || undefined,
            message: this.$t('No. of Nights is required'),
            trigger: ['blur', 'change'],
          },
        ],
      };

      if (this.form.type !== TYPE.VACANCY) {
        rules.threshold = [
          {
            required: true,
            message: this.$t('Above/Below is required'),
            trigger: ['blur', 'change'],
          },
        ];

        rules.every = [
          {
            required: true,
            type: 'number',
            transform: (value) => parseInt(value, 10) || undefined,
            message: this.$t('Every is required'),
            trigger: ['blur', 'change'],
          },
        ];
      }

      return rules;
    },
  },
  methods: {
    onTypeChange(value) {
      this.form.type = value;
      this.form.threshold = undefined;
      this.form.every = undefined;
    },
    async handleSubmit() {
      try {
        await this.$refs.form.validate();
        this.loading = true;

        await this.$store.dispatch('housekeeping-cycles/add', {
          propertyId: this.property.newPropertyCode,
          ...this.form,
        });

        this.$refs.form.resetFields();
        this.loading = false;
        this.$emit('close');
      } catch (e) {
        this.loading = false;
      }
    },
    resetFields() {
      this.$refs.form.resetFields();
      this.$emit('cancel');
    },
  },
};
</script>

<style scoped></style>
