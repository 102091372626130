<template>
  <Modal ref="modal">
    <template #default="{ hide }">
      <div>
        <h2 class="has-text-primary has-text-weight-bold is-size-4 m-b-3x">
          {{ $t('title') }}
        </h2>
        <p class="m-b-6x">
          {{ $t('desc') }}
        </p>
        <p class="has-text-right">
          <a-button type="primary" size="large" style="min-width: 120px" @click="hide">
            {{ $t('OK') }}
          </a-button>
        </p>
      </div>
    </template>
  </Modal>
</template>

<!-- eslint-disable -->
<i18n>
{
  "en": {
    "title": "Use Room Type",
    "desc": "This property has some rooms that are not associated with room type records. Please be informed that some features may not work as expected.",
    "OK": "OK"
  },
  "ja": {
    "title": "Use Room Type",
    "desc": "This property has some rooms that are not associated with room type records. Please be informed that some features may not work as expected.",
    "OK": "OK"
  }
}
</i18n>
<!-- eslint-enable -->

<script>
import Modal from '@/components/Modal';

export default {
  name: 'UseRoomTypeWarning',
  components: { Modal },
  methods: {
    show() {
      this.$refs.modal.show();
    },
  },
};
</script>

<style scoped></style>
